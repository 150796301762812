<template>
    <AddTrioProfiles v-if="displaySelectInstructor" @close="displaySelectInstructor = false" :profile="selectedProfile"
        :key="componentKey" :type="selectedType" :idTrainingAction="trainingActionId" :metierId="selectedMetier"
        @confirm="getStudentSteps" :referentId="selectedReferent" />
    <div class="">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto" v-if="props.rights === 'write'">
                <h2 class="text-base font-semibold leading-6 text-gray-900">Étapes Fest</h2>
                <p class="mt-2 text-sm text-gray-700">Par action de formation</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none" v-if="props.rights === 'write'">
                <button type="button" v-if="!cantEditItems" @click="displaySelectProfile = true; componentKey++"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                    les étapes pour un apprenant</button>
            </div>
            <div class="mt-4 sm:ml-3 sm:mt-0 sm:flex-none" v-if="props.rights === 'write'">
                <button type="button" v-if="!cantEditItems" @click="postStep(trainingActionId)"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                    pour
                    toute la formation</button>
            </div>
        </div>
        <SelectTrainingAction :action="trainingActionId" class="mt-10" @confirm="selectTrainingAction"
            @onlyOneTrainingAction="selectTrainingAction" :key="componentKey" />
        <div class="grid grid-cols-1 divide-y gap-4 mt-10">
            <div v-for="student in studentSteps" :key="student.learner.profile.id" class="pt-5">
                <div class="flex items-center">
                    <div>
                        <p class="font-medium text-gray-900 mb-2"> {{ student.learner.user.name }}
                        </p>
                    </div>
                    <div class="ml-auto flex" v-if="props.rights === 'write'">
                        <button v-if="!student.learner.profile.pivot?.metier_instructor_id" class="btn-read"
                            @click.prevent="selectedProfile = student.learner.profile.id; displaySelectInstructor = true; selectedType = 'metier'; selectedReferent = student.learner.profile.pivot?.referent_instructor_id; selectedMetier = student.learner.profile.pivot?.metier_instructor_id">
                            <UserPlusIcon class="h-5 w-5 mr-1" /> formateur
                            métier
                        </button>
                        <span v-else class="flex items-center mr-3 badge-default">
                            <UserIcon class="h-4 w-4 text-indigo-600" /><span
                                class="mr-1 font-semibold text-indigo-700">métier :
                            </span><span v-if="student.metier_instructor">{{
                                student.metier_instructor }}</span>
                            <XMarkIcon class="h-4 w-4 flex-none text-gray-400 ml-2" aria-hidden="true"
                                @click.prevent="selectedProfile = student.learner.profile.id; displayConfirmDeleteInstructor = true; selectedType = 'metier'; selectedReferent = student.learner.profile.pivot?.referent_instructor_id; selectedMetier = null; instructor_name = student.metier_instructor; student_name = student.learner.user.name" />
                        </span>
                        <button v-if="!student.learner.profile.pivot?.referent_instructor_id" class="btn-read"
                            @click.prevent="selectedProfile = student.learner.profile.id; displaySelectInstructor = true; selectedType = 'referent'; selectedMetier = null; selectedReferent = student.learner.profile.pivot?.referent_instructor_id">
                            <UserPlusIcon class="h-5 w-5 mr-1" /> formateur
                            référent
                        </button>
                        <span v-else class="flex items-center mr-3 badge-default">
                            <UserIcon class="h-4 w-4 text-indigo-600" /><span
                                class="mr-1 font-semibold text-indigo-700">référent :
                            </span><span v-if="student.referent_instructor">{{
                                student.referent_instructor }}</span>
                            <XMarkIcon class="h-4 w-4 flex-none text-gray-400 ml-2" aria-hidden="true"
                                @click.prevent="selectedProfile = student.learner.profile.id; displayConfirmDeleteInstructor = true; selectedType = 'metier'; selectedReferent = null; selectedMetier = student.learner.profile.pivot?.metier_instructor_id; instructor_name = student.referent_instructor; student_name = student.learner.user.name" />
                        </span>
                    </div>
                </div>

                <TimelineStepsByStudent :steps="student.steps.data" v-if="student.steps?.data.length > 0"
                    :key="componentKey" />
                <div v-else class="bg-gray-100 rounded-md p-4 mt-4 text-gray-700 text-sm">
                    Pas d'étape enregistrée
                </div>
            </div>
            <div v-if="studentSteps.length == 0">Pas de résultats</div>
        </div>
        <AlertMessage type="info" :title="'Veuillez sélectionner une action de formation'" v-if="!trainingActionId" />
    </div>
    <AdminDialog @confirm="editItem" :typeEdition="typeEdition" :title="confirmTexts[1].title" :size="'fullscreen'"
        @close="displayEditDialog = false" v-model="typeModelForm" :objectToModify="dataToModify"
        v-if="displayEditDialog" />
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <ConfirmationDialog @confirm="removeInstructor(selectedProfile, selectedMetier, selectedReferent)"
        v-if="displayConfirmDeleteInstructor"
        :title="confirmTexts[1].title + ' ' + instructor_name + ' du parcours de ' + student_name"
        :message="confirmTexts[1].message" :primaryActionLabel="confirmTexts[1].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[1].secondaryActionLabel" @close="displayConfirmDeleteInstructor = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
    <ModalSelectFolderForStep v-if="displayModalSelectFolder" @close="displayModalSelectFolder = false"
        @confirm="selectFolder" :type="'folder'" :training_action="trainingAction" :action="trainingActionId"
        :key="componentKey" />
    <AddStepsForAProfile v-if="displaySelectProfile" @close="displaySelectProfile = false" @confirm="selectProfile"
        :action="trainingActionId" :key="componentKey" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import AdminDialog from '@/components/dialogs/AdminDialog.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';
import { CalendarDaysIcon, MapPinIcon, ClipboardDocumentCheckIcon, ListBulletIcon } from '@heroicons/vue/24/outline';
import TimelineStepsByStudent from '@/components/steps/TimelineStepsByStudent.vue';
import SelectTrainingAction from '@/components/steps/SelectTrainingAction.vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';
import ModalSelectFolderForStep from '@/components/steps/ModalSelectFolderForStep.vue';
import AddStepsForAProfile from '@/components/steps/AddStepsForAProfile.vue';
import AddTrioProfiles from '@/components/training-actions/AddTrioProfiles.vue';
import { useRoute, useRouter } from 'vue-router';
import { UserPlusIcon, UserIcon, XMarkIcon } from '@heroicons/vue/24/outline'
const store = inject('store');
const route = useRoute();
const router = useRouter();
const currentProfile = ref(store.chosenProfile);
const cantEditItems = computed(() => (currentProfile.value.role.name === 'Formateur·trice référent·e'));

const relevantEvents = ref([]);

const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const displayZones = ref(false);
const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = ref(10);
const studentSteps = ref([]);
const trainingActionId = ref(null);
const trainingAction = ref(null);
const displayModalSelectFolder = ref(false);
const componentKey = ref(0);
const displaySelectProfile = ref(false);
const displaySelectInstructor = ref(false);
const selectedProfile = ref(null);
const selectedType = ref(null);
const selectedMetier = ref(null);
const selectedReferent = ref(null);
const displayConfirmDeleteInstructor = ref(false);
const instructor_name = ref(null);
const student_name = ref(null);

const props = defineProps({
    rights: {
        type: String,
        default: 'write',
    },
    profileId: {
        type: [Number, String],
        default: null,
    },
});

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
    {
        title: 'Détacher le formateur',
        message: 'Voulez-vous détacher ce profil du parcours de cet apprenant•e ?',
        primaryActionLabel: 'détacher',
        secondaryActionLabel: 'annuler'
    },
]

const steps = ref([
    {
        id: 1,
        name: 'Rendez-vous 1',
        step_type: 'rendezvous',
        order: 1,
    },
    {
        id: 2,
        name: 'Rendez-vous 2',
        step_type: 'rendezvous',
        order: 2,
    },
    {
        id: 3,
        name: "Situation de travail 1",
        step_type: "situation",
        order: 3,
    },
    {
        id: 4,
        name: "Situation de travail 2",
        step_type: "situation",
        order: 4,
    },
    {
        id: 5,
        name: "Situation de travail 3",
        step_type: "situation",
        order: 5,
    },
    {
        id: 6,
        name: "Situation de travail 4",
        step_type: "situation",
        order: 6,
    },
    {
        id: 7,
        name: "Bilan",
        step_type: "bilan",
        order: 7
    }
],);

const changePage = (page) => {
    fetchEventServices.fetchEvents(page, pageSize.value).then((data) => {
        relevantEvents.value = data.data;
        currentPage.value = data.current_page;
    });
};

const postStep = async (trainingActionId) => {
    let stepTemplates = [];
    stepTemplates = steps.value;
    try {
        const response = await client.post(`/api/training-action/${trainingActionId}/pre-create-steps`, {
            step_template: stepTemplates,
            include_dates: false,
            include_documents: true
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été créé';
        notificationMessage.value += '';
        getStudentSteps();
    } catch (error) {
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Creation Error';
        notificationMessage.value = `Failed to create folder`;
    }
}
const postStudentSteps = async (trainingActionId, profileId) => {
    let stepTemplates = [];
    stepTemplates = steps.value;
    try {
        const response = await client.post(`/api/training-action/${trainingActionId}/learner/${profileId}/pre-create-steps`, {
            step_template: stepTemplates,
            include_dates: false,
            include_documents: true,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = response.data.message;
        getStudentSteps();
    } catch (error) {
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = error.response.data.message;
    }
}
const getStudentSteps = async (id) => {
    const teacher = props.profileId;
    try {
        const response = await client.get(`/api/training-action/${trainingActionId.value}/learners-with-steps?per_page=40`, {
            params: { teacher_id: teacher },
        });
        studentSteps.value = response.data.data;
    } catch (error) {
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Erreur dans le chargement des étapes';
    }
}

const selectTrainingAction = (trainingAction) => {
    trainingActionId.value = trainingAction.id;
    trainingAction.value = trainingAction;
    goToSteps(trainingActionId.value);
}
const goToSteps = (trainingActionId) => {
    router.push({ query: { tab: 'steps', action: trainingActionId } });
    getStudentSteps();
}
const selectFolder = (folder) => {
    console.log(folder);
}

const selectProfile = (trainingAction, profile) => {
    postStudentSteps(trainingAction, profile);
    displaySelectProfile.value = false;
}
const removeInstructor = async (student, metier, referent) => {
    let arrayToPost = {};
    arrayToPost.profile_id = student;
    arrayToPost.role_id = 2;
    arrayToPost.metier_instructor_id = metier;
    arrayToPost.referent_instructor_id = referent;

    try {
        const response = await client.put(`api/training-actions/${trainingActionId.value}/update-profile`, arrayToPost);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le profil a bien été retiré';
        notificationMessage.value = '';
        getStudentSteps();
        displayConfirmDeleteInstructor.value = false;
    } catch (error) {
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Erreur lors de l\'ajout. ';
        notificationTitle.value += error.response.data.message;
    }
}
onMounted(() => {
    if (route.query.action) {
        trainingActionId.value = route.query.action;
        componentKey.value++;
        getStudentSteps(route.query.action);
    }
});

</script>
