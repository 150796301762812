<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <TransitionRoot as="template" :show="open">
    <Dialog class="relative z-10" @close="open = false">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-3xl">
                <form class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900"><span> {{ formFile.name
                              }}</span>
                          </DialogTitle>
                        </div>
                        <div class="flex h-7 items-center">
                          <button type="button" class="relative text-gray-400 hover:text-gray-500"
                            @click="open = false;">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-2 sm:py-0">
                      <!-- Document name -->

                      <!-- Project description -->
                      <div class="space-y-2 px-4 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label for="project-description"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Description</label>
                        </div>
                        <div class="" v-if="formFile.description">
                          <p>{{ formFile.description }}</p>
                        </div>
                        <div v-else>pas de description enregistrée</div>
                      </div>
                      <div class="space-y-2 sm:px-6 sm:py-5">
                        <h3 class="text-sm font-medium leading-6 text-gray-900">Fichiers associés</h3>
                        <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                          <li v-for="file in formFile?.files" :key="file.id"
                            class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                            <div class="flex w-0 flex-1 items-center">
                              <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <div class="ml-4 flex min-w-0 flex-1 gap-2">
                                <span class="truncate font-medium"><span v-if="file.original_file_name">{{
                                  file.original_file_name
                                    }}</span><span v-else>{{ file.file_path }}</span></span>
                              </div>
                            </div>
                            <div class="ml-4 flex-shrink-0">
                              <button @click.prevent="downloadFile(file.id)" target="_blank"
                                class="font-medium text-indigo-600 hover:text-indigo-500">Voir</button>
                            </div>
                          </li>
                        </ul>
                        <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200"
                          v-if="filesToAttach.length > 0">
                          <li v-for="file in filesToAttach" :key="file.id"
                            class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                            <div class="flex w-0 flex-1 items-center">
                              <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <div class="ml-4 flex min-w-0 flex-1 gap-2">
                                <span class="truncate font-medium">{{ file.name }}</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div class="flex justify-end space-x-3">
                      <button type="button"
                        class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        @click="open = false;">Fermer</button>
                    </div>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon, PaperClipIcon } from '@heroicons/vue/24/outline';
import UsersRelated from '@/components/files/UsersRelated.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import { useRoute } from 'vue-router';

const emit = defineEmits(['close', 'confirm', 'update:modelValue']);

const props = defineProps({
  document: [Array, Object],
  open: {
    Boolean,
    default: false,
  }
});

const formFile = ref({
  name: '',
  description: '',
  files: [],
});

const open = ref(true);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const selectedFile = ref(null);
const filesToAttach = ref([]);
const folder_parent_id = ref(null);
const route = useRoute();
const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const usersKey = ref(0);
const isSubmitted = ref(false);

const downloadFile = async (fileId) => {
  try {
    // Make an HTTP GET request to the backend to download the file
    const response = await client.get(`api/document-files/${fileId}/download`, {
      responseType: 'blob', // Expect a binary file (blob) in the response
    });

    if (response.status === 200) {
      // Try to access the Content-Disposition header from the response
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file'; // Default file name in case extraction fails

      // Extract the filename from the Content-Disposition header using a regex
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)|filename="?([^"]+)"?/);

        if (fileNameMatch) {
          if (fileNameMatch[1]) {
            // Handle the case where the filename is UTF-8 encoded
            fileName = decodeURIComponent(fileNameMatch[1]);
          } else if (fileNameMatch[2]) {
            // Handle the case where the filename is straightforward
            fileName = fileNameMatch[2];
          }
        }
      }

      // Create a Blob URL for the file content and initiate the download
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName); // Set the correct filename for the download
      document.body.appendChild(fileLink);
      fileLink.click(); // Simulate a click on the anchor to trigger the download

      // Clean up after the download
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL); // Release the blob URL
    }
  } catch (error) {
    // Handle any errors that occur during the request or download
    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur est survenue lors du téléchargement du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur est survenue lors du téléchargement du document.";
    }

    // Show an error notification to the user
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Error';
  }
};
const getDocument = async () => {
  try {
    const response = await client.get(`/api/documents/${props.document.id}`);
    formFile.value = response.data;
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans l\'affichage du document';
    notificationMessage.value = error.response.data.message;
  }
};
onMounted(async () => {
  getDocument();
});
</script>