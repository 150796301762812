<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <AddDocument v-if="displayEditFile" @close="displayEditFile = false" :type="typeSelected" :document="documentSelected"
    :no_action="!documentSelected.folder.training_action_id ? true : false" @confirm="$emit('update'); $emit('close');"
    :key="componentKey" />
  <div class="max-w-[1020px] mx-auto">
    <h2 class="mb-10 text-indigo-600 text-3xl">
      {{ documentSelected?.name }}</h2>

    <div class="my-10">
      <BreadcrumbDocument :item="documentSelected.breadcrumb" :key="componentKey" v-if="documentSelected" />
    </div>
    <div class="">
      <div class="mt-6 border-t border-gray-100 mb-5">
        <dl class="divide-y divide-gray-100">
          <div class="pr-4 py-2 md:flex">
            <dt class="text-sm font-medium leading-5 text-gray-900 w-60">Créé le</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0" v-if="documentSelected?.created_at">
              {{ fetchEventServices.formatDate(new Date(documentSelected?.created_at)) }}
            </dd>
          </div>
          <div class="pr-4 py-2 md:flex items-center"
            v-if="documentSelected?.permissions.write || currentProfile?.role.name === 'Administrateur·trice'">
            <dt class="text-sm font-medium leading-5 text-gray-900 w-60">Partagé avec les personnes</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div>
                <UsersRelated type="document" :item="documentSelected?.id" :key="componentKey"
                  v-if="documentSelected" />
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <p v-html="documentSelected?.description" class="mt-10"></p>

      <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200 mt-20">
        <li v-for="file in documentSelected?.files" :key="file.id"
          class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <div class="ml-4 flex min-w-0 flex-1 gap-2">
              <span class="truncate font-medium"><span v-if="file.original_file_name">{{ file.original_file_name
                  }}</span><span v-else>{{ file.file_path }}</span></span>
            </div>
          </div>
          <div class="ml-4 flex-shrink-0">
            <a :href="file.original_file_name" target="_blank" @click.prevent="downloadFile(file.id)"
              class="font-medium text-indigo-600 hover:text-indigo-500">Télécharger</a>
          </div>
        </li>
      </ul>
      <button @click.prevent="editFile('edition')" class="btn-primary mt-10 !text-lg"
        v-if="documentSelected?.permissions?.write">Modifier</button>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { PaperClipIcon } from '@heroicons/vue/20/solid';
import AddDocument from '@/components/files/AddDocument.vue';
import UsersRelated from '@/components/files/UsersRelated.vue';
import fetchEventServices from '@/services/EventServices.js';
import client from '@/api/client';
import BreadcrumbDocument from '@/components/files/BreadcrumbDocument.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const displayEditFile = ref(false);
let typeSelected = ref(null);
let componentKey = ref(0);
let documentSelected = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const props = defineProps({
  document: [Object, Array],
});
const emit = defineEmits(['update', 'close']);

const editFile = (type) => {
  typeSelected.value = type;
  displayEditFile.value = true;
  componentKey.value += 1;
}

const downloadFile = async (fileId) => {
  try {
    // Make an HTTP GET request to the backend to download the file
    const response = await client.get(`api/document-files/${fileId}/download`, {
      responseType: 'blob', // Expect a binary file (blob) in the response
    });

    if (response.status === 200) {
      // Try to access the Content-Disposition header from the response
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file'; // Default file name in case extraction fails

      // Extract the filename from the Content-Disposition header using a regex
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)|filename="?([^"]+)"?/);

        if (fileNameMatch) {
          if (fileNameMatch[1]) {
            // Handle the case where the filename is UTF-8 encoded
            fileName = decodeURIComponent(fileNameMatch[1]);
          } else if (fileNameMatch[2]) {
            // Handle the case where the filename is straightforward
            fileName = fileNameMatch[2];
          }
        }
      }

      // Create a Blob URL for the file content and initiate the download
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName); // Set the correct filename for the download
      document.body.appendChild(fileLink);
      fileLink.click(); // Simulate a click on the anchor to trigger the download

      // Clean up after the download
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL); // Release the blob URL
    }
  } catch (error) {
    // Handle any errors that occur during the request or download
    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur est survenue lors du téléchargement du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur est survenue lors du téléchargement du document.";
    }

    // Show an error notification to the user
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Error';
  }
};

onMounted(() => {
  documentSelected.value = props.document;
  componentKey.value += 1;
});
</script>
