<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <TransitionRoot as="template" :show="open">
    <Dialog class="relative z-10" @close="$emit('close')">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-3xl">
                <form class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900"><span
                              v-if="type === 'edition'">Modifier un document</span><span v-else>Nouveau document</span>
                          </DialogTitle>
                        </div>
                        <div class="flex h-7 items-center">
                          <button type="button" class="relative text-gray-400 hover:text-gray-500"
                            @click="$emit('close')">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-2 sm:py-0">
                      <!-- Document name -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label for="project-name"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Nom du document</label>
                        </div>
                        <div class="sm:col-span-2">
                          <input type="text" name="project-name" id="project-name" v-model="formFile.name"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>

                      <!-- Project description -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label for="project-description"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Description</label>
                        </div>
                        <div class="sm:col-span-2">
                          <textarea id="project-description" name="project-description" rows="3"
                            v-model="formFile.description"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>

                      <!-- Team members -->
                      <div
                        v-if="(currentProfile.role.name !== 'Apprenant·e' && currentProfile.role.name !== 'Formateur·trice métier')">
                        <div class="space-y-2 sm:px-6 sm:py-5" v-if="!no_action">
                          <AddUserRelated type="document" :item="document.id" v-if="document" :key="usersKey"
                            :action="document?.folder.training_action_id" />
                          <AddRolesRelated type="document" :folder="formFile"
                            v-if="formFile.id && currentProfile.role.name !== 'Apprenant·e' && currentProfile.role.name !== 'Formateur·trice métier'"
                            @confirm="usersKey += 1" />
                        </div>
                      </div>
                      <div class="space-y-2 sm:px-6 sm:py-5">
                        <h3 class="text-sm font-medium leading-6 text-gray-900">Fichiers associés</h3>
                        <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                          <li v-for="file in formFile?.files" :key="file.id"
                            class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                            <div class="flex w-0 flex-1 items-center">
                              <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <div class="ml-4 flex min-w-0 flex-1 gap-2">
                                <span class="truncate font-medium"><span v-if="file.original_file_name">{{
                                  file.original_file_name
                                    }}</span><span v-else>{{ file.file_path }}</span></span>
                              </div>
                            </div>
                            <div class="ml-4 flex-shrink-0">
                              <button @click.prevent="downloadFile(file.id)" target="_blank"
                                class="font-medium text-indigo-600 hover:text-indigo-500">Voir</button>
                            </div>
                            <div class="ml-4 flex-shrink-0">
                              <button @click.prevent="removeFile(file.id)"
                                class="font-medium text-indigo-600 hover:text-indigo-500">Supprimer</button>
                            </div>
                          </li>
                        </ul>
                        <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200"
                          v-if="filesToAttach.length > 0">
                          <li v-for="file in filesToAttach" :key="file.id"
                            class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                            <div class="flex w-0 flex-1 items-center">
                              <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <div class="ml-4 flex min-w-0 flex-1 gap-2">
                                <span class="truncate font-medium">{{ file.name }}</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="space-y-2 sm:px-6 sm:py-5">
                        <button type="button"
                          class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <label for="carte-grise" class="block text-sm font-medium leading-6 text-gray-900">
                            <svg class="mb-3 mx-auto h-12 w-12 text-gray-400 cursor-pointer" stroke="currentColor"
                              fill="#6b7280" width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="m849.91 1089.1c7.3633 0 13.332-5.9648 13.332-13.332v-66.316h83.543c7.3633 0 13.332-5.9648 13.332-13.332v-871.97c0-7.3711-5.9688-13.332-13.332-13.332h-613.61c-7.3633 0-13.332 5.9648-13.332 13.332v76.184h-66.621c-7.3633 0-13.332 5.9648-13.332 13.332v862.1c0 7.3711 5.9688 13.332 13.332 13.332zm-503.41-951.61h586.95v845.3h-70.207v-769.12c0-7.3711-5.9688-13.332-13.332-13.332h-503.41zm-79.953 89.516h570.03v835.43h-570.03z" />
                              <path
                                d="m708.29 776.61h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3672-5.9727-13.332-13.336-13.332z" />
                              <path
                                d="m708.29 858.45h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332s-5.9727-13.332-13.336-13.332z" />
                              <path
                                d="m708.29 940.3h-313.46c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h313.46c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9727-13.332-13.336-13.332z" />
                              <path
                                d="m422.41 563.63c0 7.3711 5.9688 13.332 13.332 13.332h22.293c20.18 52.465 70.969 89.855 130.45 89.855 20.078 0 39.504-4.207 57.746-12.5 6.6992-3.0469 9.6602-10.949 6.6133-17.656-3.0469-6.707-10.965-9.6875-17.656-6.6133-14.746 6.707-30.461 10.105-46.707 10.105-44.445 0-82.879-25.82-101.38-63.191h104.48c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9688-13.332-13.332-13.332h-113.83c-1.5859-7.5352-2.4453-15.332-2.4453-23.332 0-7.9961 0.85938-15.789 2.4414-23.32h113.83c7.3633 0 13.332-5.9648 13.332-13.332 0-7.3711-5.9688-13.332-13.332-13.332h-104.49c18.504-37.371 56.938-63.191 101.39-63.191 16.238 0 31.953 3.3984 46.719 10.09 6.6719 3.0742 14.602 0.078126 17.648-6.6289 3.0391-6.707 0.070312-14.609-6.6328-17.645-18.25-8.293-37.676-12.488-57.734-12.488-59.48 0-110.27 37.395-130.45 89.855h-22.293c-7.3633 0-13.332 5.9648-13.332 13.332 0 7.3711 5.9688 13.332 13.332 13.332h15.004c-1.2852 7.6016-2.1055 15.359-2.1055 23.32 0 7.9648 0.82422 15.727 2.1094 23.332h-15.004c-7.3477 0.007812-13.32 5.9688-13.32 13.34z" />
                            </svg>
                            <span class="mt-2 block text-sm font-semibold text-gray-900">Déposer votre fichier</span>
                            <p class="text-gray-500">(pdf, word, excel, docx, csv, xlsx, png, jpg, ... max: 20MB)</p>
                            <input id="carte-grise" name="carte-grise" type="file" @change="selectFile"
                              class="sr-only" />
                          </label>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div class="flex justify-end space-x-3">
                      <button type="button"
                        class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        @click="$emit('close')">Annuler</button>
                      <button type="submit" @click.prevent="uploadFile" :disabled="isSubmitted"
                        class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enregistrer</button>
                    </div>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon, PaperClipIcon } from '@heroicons/vue/24/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid';
import AddUserRelated from '@/components/files/AddUserRelated.vue'
import AddRolesRelated from '@/components/files/AddRolesRelated.vue'
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import { useRoute } from 'vue-router';

const emit = defineEmits(['close', 'confirm']);
const formFile = ref({
  name: '',
  description: '',
  privacy: 'public',
  team: [],
});
const props = defineProps({
  type: String,
  document: [Array, Object],
  no_action: {
    Boolean,
    default: false,
  },
  folder_id: {
    type: Number,
    default: null,
  }
});
const open = ref(true)
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const selectedFile = ref(null);
const filesToAttach = ref([]);
const folder_parent_id = ref(null);
const route = useRoute();
const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const usersKey = ref(0);
const isSubmitted = ref(false);

const postFile = async (formData) => {
  if (!isSubmitted.value) {
    isSubmitted.value = true;
    try {
      const response = await client.post(`/api/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo, l\'item a bien été créé';
      notificationMessage.value += '';
      console.log('document', response.data.id);
      emit('confirm', response.data.id);
    } catch (error) {
      showNotification.value = true;
      notificationType.value = 'error';
      notificationTitle.value = 'Erreur dans la création du document';
      notificationMessage.value = error.response.data.message;
    }
  }
}

const putFile = async (formData) => {
  try {
    const response = await client.post(`/api/documents/${props.document.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        _method: "PUT",
      },
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value += '';
    emit('confirm');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans la modification du document';
    notificationMessage.value = error.response.data.message;
  }
}
const removeFile = async (fileId) => {
  try {
    const response = await client.delete(`/api/document-files/${fileId}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value += '';
    emit('confirm');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = `Failed to create document`;
  }
}


const selectFile = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedFile.value = file;
    filesToAttach.value.push(file);
  }
  if (!formFile.value.name || formFile.value.name === '') {
    const fileName = selectedFile.value.name.split('.').slice(0, -1).join('.');
    formFile.value.name = fileName;
  }
}

const uploadFile = async () => {
  const formData = new FormData();
  formData.append('name', formFile.value.name);
  formData.append('description', formFile.value.description);
  formData.append('document_id', props.document?.id || null);
  // formData.append('original_file_name', formFile.value.name);
  // Add selected files to FormData
  Array.from(filesToAttach.value).forEach((document, index) => {
    formData.append(`files[]`, document);
  });
  if (props.type === 'edition') {
    putFile(formData);
  } else {
    postFile(formData);
    formData.append('folder_id', folder_parent_id.value);
  }
}

const downloadFile = async (fileId) => {
  try {
    // Make an HTTP GET request to the backend to download the file
    const response = await client.get(`api/document-files/${fileId}/download`, {
      responseType: 'blob', // Expect a binary file (blob) in the response
    });

    if (response.status === 200) {
      // Try to access the Content-Disposition header from the response
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file'; // Default file name in case extraction fails

      // Extract the filename from the Content-Disposition header using a regex
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)|filename="?([^"]+)"?/);

        if (fileNameMatch) {
          if (fileNameMatch[1]) {
            // Handle the case where the filename is UTF-8 encoded
            fileName = decodeURIComponent(fileNameMatch[1]);
          } else if (fileNameMatch[2]) {
            // Handle the case where the filename is straightforward
            fileName = fileNameMatch[2];
          }
        }
      }

      // Create a Blob URL for the file content and initiate the download
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName); // Set the correct filename for the download
      document.body.appendChild(fileLink);
      fileLink.click(); // Simulate a click on the anchor to trigger the download

      // Clean up after the download
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL); // Release the blob URL

    }
  } catch (error) {
    // Handle any errors that occur during the request or download
    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur est survenue lors du téléchargement du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur est survenue lors du téléchargement du document.";
    }

    // Show an error notification to the user
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Error';
  }
};

onMounted(async () => {
  if (props.document) {
    formFile.value = props.document;
    folder_parent_id.value = props.document.folder_id;
  } else {
    if (route.params.idFolder) {
      folder_parent_id.value = route.params.idFolder;
    } else if (props.folder_id) {
      folder_parent_id.value = props.folder_id;
    }
  }
});
</script>