<template>
  <div class="mb-10">
    <h2 class=" mt-10 text-3xl">Mes apprenants</h2>
    <AdminSteps rights="'read'" :profileId="currentProfile.id" />
  </div>

  <h2 class="mb-10 mt-20 text-3xl">Mes documents</h2>
  <router-link to="/documents/"><button type="button"
      class=" relative block w-full rounded-lg border border-slid border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-gray-50">

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"
        class="mx-auto h-12 w-12 text-gray-400">
        <path stroke-linecap=" round" stroke-linejoin="round"
          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
      </svg>
      <span class="mt-2 block text-sm font-medium text-gray-900">Voir tous mes documents</span>
    </button></router-link>
</template>

<script setup>
/* eslint-disable */
import {
  inject, onMounted, watch, ref,
} from 'vue';
import AdminSteps from '@/components/admin/AdminSteps.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
</script>
