<template>
    <div class="">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h2 class="text-base font-normal leading-6 text-gray-900"><span class="text-3xl font-medium mr-3">{{
                    totalItems }}
                    </span><span>Utilisateurs</span></h2>
                <p class="mt-2 text-sm text-gray-700">Liste des utilisateurs enregistrées</p>
            </div>

            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button type="button" @click.prevent="displayCreateUser = true"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                    un utilisateur</button>
            </div>
        </div>
        <div
            class="gap-8 mt-5 bg-gray-50 rounded-md xl:rounded-full p-4 place-content-center grid grid-cols-2 md:grid-cols-3 xl:flex">
            <div v-for="statItem in usersCount" :key="statItem.id" class="flex items-center">
                <dd class="mr-2 inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700"
                    :class="'card-role' + statItem.id">{{ statItem.profiles_count }}
                </dd>
                <dt class="truncate text-sm font-medium text-gray-500">{{ statItem.name }}</dt>

            </div>
        </div>
        <!-- search line -->
        <div class="flex items-center flex-wrap mt-5">
            <div>
                <div class="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                    <div class="w-full max-w-lg lg:max-w-xs">
                        <label for="search" class="sr-only">rechercher</label>
                        <div class="relative">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon class="size-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input id="search" name="search" @change="searchProfiles($event.target.value)"
                                v-model="selectedKeyword"
                                class="block w-full rounded-md border-0 bg-white pl-10 pr-3 !py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/5"
                                placeholder="Rechercher" type="search" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mr-2">
                <SelectComponent v-model="selectedRole" :arrayData="relevantRoles" :label="''" class=""
                    @change="searchProfiles()" :placeholder="'Choisissez un rôle'" :key="componentKey"
                    :widthClass="'min-w-52'" />
            </div>
            <SelectTrainingActionComponent v-model="selectedAction" :arrayData="relevantTrainingActions" :label="''"
                class="" @change="searchProfiles()" :placeholder="'Choisissez une action'" :key="componentKey"
                :widthClass="'min-w-96'" />

            <button class="btn-read mx-2" @click.prevent="clearFilter">effacer les filtres</button>
            <button class="btn-primary mr-2" @change="searchProfiles()">rechercher</button>

        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Nom
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Organisation
                                </th>

                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Profil
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Page publique</span>
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Edition</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200">
                            <tr v-for="relevantProfile in relevantProfiles" :key="relevantProfile.id">
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-indigo-700 font-medium"
                                    v-if="relevantProfile.user.name">{{
                                        relevantProfile.user.name }}</td>

                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span
                                        v-if="relevantProfile.organization">{{
                                            relevantProfile.organization.name }}</span>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-32">
                                    <span :class="'bg-role' + relevantProfile.role.id"
                                        class="inline-flex items-center gap-x-1.5 rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium">

                                        {{
                                            relevantProfile.role.name }}
                                    </span>
                                </td>
                                <td>
                                    <p v-if="relevantProfile.structures.length > 0">
                                        <span
                                            class="mr-1 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                            v-for="(attachedStructure) in relevantProfile.structures"
                                            :key="attachedStructure.id">
                                            <svg class="h-1.5 w-1.5 fill-indigo-500" viewBox="0 0 6 6"
                                                aria-hidden="true">
                                                <circle cx="3" cy="3" r="3" />
                                            </svg>{{
                                                attachedStructure.name
                                            }}</span>
                                    </p>
                                    <p v-else
                                        class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                        Aucune
                                        structure attachée</p>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <router-link :to="'/utilisateurs/profil/' + relevantProfile.id" class="text-indigo-600
                                        hover:text-indigo-900">Voir<span class="sr-only">, {{ relevantProfile.name
                                            }}</span></router-link>
                                </td>
                                <td v-if="!cantEditItems"
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <router-link :to="'/utilisateurs/profil/modifier-mon-profil/' + relevantProfile.id"
                                        class="text-indigo-600 hover:text-indigo-900">Modifier<span class="sr-only">, {{
                                            relevantProfile.name }}</span></router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
                </div>
            </div>
        </div>
    </div>
    <AdminUserDialog @confirm="showNotification = true" v-if="displayCreateUser"
        @close="displayCreateUser = false; fetchProfiles()" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import AdminUserDialog from '@/components/dialogs/AdminUserDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import SelectComponent from '@/components/form/SelectComponent.vue';
import SelectTrainingActionComponent from '@/components/form/SelectTrainingActionComponent.vue';
import { debounce } from 'lodash';
import fetchServices from '@/services/fetchServices.js';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const cantEditItems = computed(() => (currentProfile.value.role.name === 'Formateur·trice référent·e'));

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayCreateUser = ref(false);

const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = 20;
const totalItems = ref(0);
const usersCount = ref([]);
const componentKey = ref(0);
const relevantTrainingActions = ref([]);
const selectedRole = ref(null);
const selectedAction = ref(null);
const filteredPeople = ref([]);
const selectedKeyword = ref(null);

const relevantProfiles = ref([]);
const relevantRoles = ref([
    { id: 4, name: 'Formateur·trice référent·e' },
    { id: 3, name: 'Formateur·trice métier' },
    { id: 2, name: 'Apprenant·e' },
    { id: 5, name: 'Assistant·e de formation' },
    { id: 6, name: 'Coordinateur·trice' },
    { id: 1, name: 'Administrateur·trice' },
    { id: null, name: 'Choisissez un rôle' },
]);

const fetchProfiles = async (page = 1) => {
    try {
        const response = await client.get('/api/all-profiles', {
            params: { page, per_page: pageSize },
        });
        relevantProfiles.value = response.data.data;
        currentPage.value = response.data.current_page;
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
    } catch (error) {
        console.error('Failed to fetch profiles :', error);
    }
};

const searchProfiles = debounce(async () => {
    const params = new URLSearchParams();
    // if (keyword) { params.append('name', keyword) };
    if (selectedKeyword.value) {
        params.append('name', selectedKeyword.value);
    }
    if (selectedAction.value) {
        params.append('training_action_id', selectedAction.value.id);
    }
    if (selectedRole.value) {
        params.append('role_id', selectedRole.value.id);
    }
    try {
        const response = await client.get(`api/profiles-search?${params}`);
        relevantProfiles.value = response.data;
    } catch (error) {
        console.error('Failed to fetch profile:', error);
    }
}, 600);

const fetchTrainingActions = () => {
    fetchServices.fetchTrainingActions(1, 100).then((data) => {
        relevantTrainingActions.value = data.data;
        componentKey.value += 1;
    });
}
const fetchUserCounts = async () => {
    try {
        const response = await client.get('/api/roles/user-count');
        usersCount.value = response.data;
    } catch (error) {
        console.error('Failed to fetch user counts :', error);
    }
};

const clearFilter = () => {
    selectedRole.value = null;
    selectedAction.value = null;
    selectedKeyword.value = null;
    searchProfiles();
    componentKey.value += 1;
};

onMounted(() => {
    fetchTrainingActions();
    fetchProfiles(1);
    fetchUserCounts();
});

const changePage = (page) => {
    fetchProfiles(page);
};


</script>