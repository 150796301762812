<template>
    <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3" v-if="props.currentProfile == 'Administrateur·trice'">
        <div v-for="item in stats" :key="item.name" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt class="truncate text-sm font-medium text-gray-500">{{ item.name }}</dt>
            <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ item.stat }}</dd>
        </div>
    </div>
    <div
        class="mt-10 divide-y divide-gray-200 overflow-hidden  shadow rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
        <div v-for="(action, actionIdx) in actions" :key="action.title" class=" shadow"
            :class="[actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '', actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
            <div>
                <span
                    :class="[action.iconBackground, action.iconForeground, 'inline-flex rounded-lg p-3 ring-4 ring-white']">
                    <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
                </span>
            </div>
            <div class="mt-8">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                    <router-link to="/documents" v-if="action.title === 'Médiathèque'"> <button
                            class="focus:outline-none">
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true" />
                            {{ action.title }}
                        </button></router-link>
                    <button class="focus:outline-none" @click="$emit('update:modelValue', action.tab)" v-else>
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        {{ action.title }}
                    </button>
                </h3>
                <p class="mt-2 text-sm text-gray-500">{{ action.description }}</p>
            </div>
            <span class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true">
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path
                        d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
            </span>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import {
    UsersIcon,
    HomeIcon,
    UserIcon,
    TagIcon,
    BuildingLibraryIcon,
    AcademicCapIcon,
    PaperAirplaneIcon,
    DocumentIcon,
    FlagIcon, HomeModernIcon
} from '@heroicons/vue/24/outline';
import client from '@/api/client';
import { ref, reactive, onMounted } from 'vue';

const props = defineProps({
    currentProfile: [String],
});

const model = defineModel();
const actions = ref();
const navigation = [
    {
        title: 'Structures',
        tab: 'structures',
        icon: HomeModernIcon,
        iconForeground: 'text-teal-700',
        iconBackground: 'bg-teal-50',
        description: 'Liste des structures enregistrées',
        allowedRoles: ['Administrateur·trice']
    },
    {
        title: 'Utilisateurs',
        tab: 'users',
        icon: UsersIcon,
        iconForeground: 'text-purple-700',
        iconBackground: 'bg-purple-50',
        description: 'Liste des utilisateurs enregistrés. Ici vous pouvez vérifier à quelles actions de formation un utilisateur est associé',
        allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice']
    },
    {
        title: 'Organisations',
        tab: 'organizations',
        icon: BuildingLibraryIcon,
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
        description: 'Liste des organisations enregistrées',
        allowedRoles: ['Administrateur·trice']
    },
    {
        title: 'Types de structures',
        tab: 'types',
        icon: HomeIcon,
        iconForeground: 'text-yellow-700',
        iconBackground: 'bg-yellow-50',
        description: 'Liste des types de structures enregistrés',
        allowedRoles: ['Administrateur·trice']
    },
    {
        title: 'Statuts',
        tab: 'legalForms',
        icon: TagIcon,
        iconForeground: 'text-rose-700',
        iconBackground: 'bg-rose-50',
        description: 'Liste des statuts enregistrés',
        allowedRoles: ['Administrateur·trice']
    },
    {
        title: 'Publics',
        tab: 'publics',
        icon: UserIcon,
        iconForeground: 'text-indigo-700',
        iconBackground: 'bg-indigo-50',
        description: 'Liste des publics enregistrés',
        allowedRoles: ['Administrateur·trice']
    },
    {
        title: 'Diplômes',
        tab: 'degrees',
        icon: AcademicCapIcon,
        iconForeground: 'text-green-700',
        iconBackground: 'bg-green-50',
        description: 'Liste des diplômes enregistrés',
        allowedRoles: ['Administrateur·trice']
    },
    {
        title: 'Actions de formation',
        tab: 'training-actions',
        icon: AcademicCapIcon,
        iconForeground: 'text-green-700',
        iconBackground: 'bg-green-50',
        description: 'Liste des actions de formations',
        allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice']
    },
    {
        title: 'Étapes fest',
        tab: 'steps',
        icon: FlagIcon,
        iconForeground: 'text-lime-700',
        iconBackground: 'bg-lime-50',
        description: 'Suivre les étapes FEST pour une action de formation',
        allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice']
    },
    {
        title: 'Vœux',
        tab: 'wishlists',
        icon: PaperAirplaneIcon,
        iconForeground: 'text-green-700',
        iconBackground: 'bg-cyan-50',
        description: 'Liste des vœux enregistrés',
        allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice']
    },
    {
        title: 'Médiathèque',
        tab: 'documents',
        link: '/documents',
        icon: DocumentIcon,
        iconForeground: 'text-lime-700',
        iconBackground: 'bg-lime-50',
        description: 'Gestion des documents pour une action de formation',
        allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice']
    },
]
const stats = reactive([
    { name: 'Structures', stat: '0' },
    { name: 'Utilisateurs', stat: '0' },
    { name: 'Organisations', stat: '0' },
]);
const totalProfiles = ref(0);
const totalStructures = ref(0);
const totalOrganizations = ref(0);

const fetchProfiles = async (page = 1) => {
    try {
        const response = await client.get('/api/all-profiles', {
            params: { page, per_page: 10 },
        });
        totalProfiles.value = response.data.total;
        stats[1].stat = totalProfiles.value;
    } catch (error) {
        console.error('Failed to fetch profiles :', error);
    }
};
const fetchStructures = async (page = 1) => {
    try {
        const response = await client.get('/api/structures/search/filter?', {
            params: { page, per_page: 10 },
        });
        totalStructures.value = response.data.total;
        stats[0].stat = totalStructures.value;
    } catch (error) {
        console.error('Failed to fetch profiles :', error);
    }
};
const fetchOrganizations = async (page = 1) => {
    try {
        const response = await client.get('/api/organizations', {
            params: { page, per_page: 10 },
        });
        totalOrganizations.value = response.data.length;
        stats[2].stat = totalOrganizations.value;
    } catch (error) {
        console.error('Failed to fetch profiles :', error);
    }
};
onMounted(() => {
    fetchProfiles();
    fetchStructures();
    fetchOrganizations();
    actions.value = navigation.filter(tab => tab.allowedRoles.includes(props.currentProfile));
}); 
</script>