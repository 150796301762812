<template>
  <ReadDocument v-if="displayDocument" @close="displayDocument = false" :document="documentSelected"
    :key="componentKey" />
  <ModalNoteRead v-model:open="displayNoteRead" @close="displayNoteRead = false" :note="noteSelected"
    :key="componentKey" />
  <TrainingActionStepChangeDate :step="selectedStep?.id" :start="selectedStep.start_datetime"
    :end="selectedStep?.end_datetime" v-if="displayDateEdition"
    @close="displayDateEdition = false; console.log('close')" :key="componentKey" @confirm="getTrainigStep" />
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <AddDocument v-if="displayEditFile" @close="displayEditFile = false" :type="typeSelected" :document="documentSelected"
    :folder_id="trainingStep.folders[0].id" :key="componentKey" @confirm="attachFile" />
  <AppLayout>
    <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
      <div class="min-w-0 flex-1 !mb-10 ">
        <div class="hero-structures max-w-[1520px] mx-auto">
          <h1 class="title1 text-5xl mb-5 !mt-20 text-white">
            {{ templateStep?.name }}
          </h1>
          <h2 class="text-2xl text-indigo-600 text-white">
            <span v-if="trainingStep.start_datetime">du {{
              fetchEventServices.formatDateSimple(new
                Date(trainingStep.start_datetime)) }}</span> <span v-if="trainingStep.end_datetime">au {{
                fetchEventServices.formatDateSimple(new
                  Date(trainingStep.end_datetime)) }}</span>
          </h2>
          <p class="text-white text-xl mt-3" v-if="currentProfile.role?.name !== 'Apprenant·e'">{{
            trainingStep.profile?.user?.name }}</p>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-1 sm:pt-8 rounded-xl" id="trainingStep">
      <div class=" mx-auto max-w-[1100px] mt-20">
        <router-link class="" to="/tableau-de-bord"
          v-if="currentProfile.role?.name === 'Apprenant·e' || currentProfile.role?.name === 'Formateur•rice métier'">
          <div class="flex items-center mb-10 hover:text-indigo-700">
            <ChevronLeftIcon class="h-8 w-8 flex-shrink-0 text-indigo-600 ml-1 sm:-ml-2" aria-hidden="true" /> Retour
            Parcours
            Fest
          </div>
        </router-link>
        <router-link class="" :to="'/tableau-de-bord?tab=steps&action=' + trainingStep.training_action_id" v-else>
          <div class="flex items-center mb-10 hover:text-indigo-700">
            <ChevronLeftIcon class="h-8 w-8 flex-shrink-0 text-indigo-600 ml-1 sm:-ml-2" aria-hidden="true" /> Retour
            Action de formation
          </div>
        </router-link>
        <div class="mb-10" id="intro">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-semibold leading-7 text-indigo-700">Présentation</h3>
            <p class="mt-1 max-w-2xl text-base leading-7 text-gray-500">Présentation de l'étape et ses enjeux</p>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading-7 text-gray-900">Rôles attendus</dt>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0" v-if="templateStep?.roles">
                  <span v-for="role in templateStep?.roles" :key="role.role_id">
                    <span class="roleBadge bg-role6 mr-2"
                      v-if="role.role_name.includes('Encadrement')">Encadrement</span>
                    <span class="roleBadge bg-role3 mr-2" v-if="role.role_name.includes('métier')">Formateur•rice
                      métier</span><span class="roleBadge bg-role4 mr-2"
                      v-if="role.role_name.includes('référent')">Formateur•rice
                      référent</span>
                    <span class="roleBadge bg-role2 mr-2" v-if="role.role_name.includes('Apprenant')">{{ role.role_name
                      }}</span>
                  </span>
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading-7 text-gray-900">Date</dt>
                <dd class="mt-1 flex text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div class="flex-grow">
                    <div class="flex w-full flex-none gap-x-2">
                      <div class="flex-none">
                        <span class="sr-only">Due date</span>
                        <CalendarDaysIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <div class="text-base leading-7 text-gray-500">
                        <time datetime="2023-01-31"><span v-if="trainingStep.start_datetime">{{
                          fetchEventServices.formatDateSimple(new
                            Date(trainingStep.start_datetime)) }}</span> - <span v-if="trainingStep.end_datetime">{{
                              fetchEventServices.formatDateSimple(new
                                Date(trainingStep.end_datetime)) }}</span></time>
                      </div>
                    </div>
                  </div>
                  <span class="ml-4 flex-shrink-0">
                    <button type="button"
                      @click="selectedStep = trainingStep; displayDateEdition = true; componentKey++"
                      class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">Modifier</button>
                  </span>
                </dd>

              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading- text-gray-900">Enjeu, objectif de cette étape</dt>

                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div v-for="goal in templateStep?.goals" :key="goal">
                    <div v-html="goal"></div>
                  </div>

                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" v-if="templateStep?.expectations">
                <dt class="text-base font-medium leading-7 text-gray-900">Attentes</dt>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div v-for="expectation in templateStep?.expectations" :key="expectation">
                    <div v-html="expectation"></div>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="mb-32" id="before">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-semibold leading-7  text-indigo-700" v-if="templateStep?.step_type === 'situation'">
              L'entretien réflexif amont
            </h3>
            <h3 class="text-xl font-semibold leading-7  text-indigo-700" v-else>
              Documents supports
            </h3>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="">

              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0"
                  v-if="templateStep?.step_type === 'situation'">
                  {{ templateStep?.reflexive_interview_amont_text }}
                </dd>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0" v-else>
                  Vous pouvez utiliser les documents suivants pour animer le rendez-vous, expliquer la Fest, le déroulé
                  des situations de travail...</dd>
              </div>
              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-2 text-base text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200"
                    v-if="templateStep?.step_type === 'situation'">
                    <li class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7"
                      v-for="file in templateStep?.reflexive_interview_amont_documents" :key="file">
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium">{{ file?.name }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <button @click.prevent="downloadFile(file.id)" target="_blank"
                          class="font-medium text-indigo-600 hover:text-indigo-500">Voir</button>
                      </div>
                    </li>

                  </ul>
                  <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200" v-else>
                    <li class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7"
                      v-for="file in templateStep?.support_documents" :key="file">
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium">{{ file?.name }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <button @click.prevent="downloadFile(file.id)" target="_blank"
                          class="font-medium text-indigo-600 hover:text-indigo-500">Voir</button>
                      </div>
                    </li>

                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="mb-32" id="before" v-if="templateStep?.step_type === 'situation'">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-semibold leading-7  text-indigo-700">
              L'entretien réflexif aval
            </h3>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="">

              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ templateStep?.reflexive_interview_aval_text }}
                </dd>
              </div>
              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-2 text-base text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                    <li class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7"
                      v-for="file in templateStep?.reflexive_interview_aval_documents" :key="file">
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium">{{ file?.name }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <button @click.prevent="downloadFile(file.id)" target="_blank"
                          class="font-medium text-indigo-600 hover:text-indigo-500">Voir</button>
                      </div>
                    </li>

                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="bg-indigo-50 mt-10 shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <div class="">
              <div class="flex items-center gap-4">
                <div v-if="templateStep?.step_type === 'rendezvous'">
                  <h3 class="text-base font-semibold leading-7  text-indigo-700">
                    <span>Ce que l'on retient de l'entretien</span>
                  </h3>
                  <div class="mt-2 text-base text-gray-500">
                    <div>
                      Décisions prises, éléments à retenir, synthèse des échanges, ...
                    </div>
                  </div>
                </div>
                <div v-if="templateStep?.step_type === 'situation'">
                  <h3 class="text-base font-semibold leading-7  text-indigo-700">
                    <span>Document à rédiger</span>
                  </h3>
                  <div class="mt-2 text-base text-gray-500">
                    <div>
                      Vous pouvez, soit déposer le positionnement papier rempli de l’apprenant (scan ou photo), soit
                      rédiger en ligne directement ce positionnement.
                    </div>
                  </div>
                </div>
                <div v-if="templateStep?.step_type === 'bilan'">
                  <h3 class="text-base font-semibold leading-7  text-indigo-700">
                    <span>Ce que l'on retient de ces mises en situation</span>
                  </h3>
                  <div class="mt-2 text-base text-gray-500">
                    <div>
                      Points à noter pour de futures promos, pour l’accompagnement de l’apprenant, ...
                    </div>
                  </div>
                </div>
                <div class="ml-auto"><button type="button"
                    class="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 whitespace-nowrap"
                    v-if="templateStep?.delivrable_template">
                    <ArrowUpTrayIcon class="w-4 h-4 mr-2" />Télécharger
                    le modèle
                  </button></div>
              </div>

              <div class="mt-10 sm:mt-5 sm:flex sm:flex-shrink-0 sm:items-center">
                <router-link :to="'edition/livrable/' + $route.params.idTrainingActionStep"><button type="button"
                    v-if="templateStep?.delivrable?.length > 0 && !trainingStep?.delivrables"
                    class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <PencilSquareIcon class="w-4 h-4 mr-2" />Rédiger
                    en ligne
                  </button></router-link>
                <button type="button" @click.prevent="editFile('creation')"
                  class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <ArrowDownTrayIcon class="w-4 h-4 mr-2" />Déposer
                  un fichier
                </button>
                <router-link :to="'edition/note/' + $route.params.idTrainingActionStep"> <button type="button"
                    class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <ChatBubbleBottomCenterTextIcon class="w-4 h-4 mr-2" />Ajouter
                    un commentaire
                  </button></router-link>
              </div>

              <div class="rounded-md border border-gray-200 bg-white mt-5"
                v-for="deliverable in trainingStep?.deliverables" :key="deliverable.index">

                <div class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7">
                  <div class="flex w-0 flex-1 items-center">
                    <div class="mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <router-link :to="'lecture/livrable/' + $route.params.idTrainingActionStep"> <span
                          class="truncate font-medium">{{ deliverable?.name }}</span></router-link>

                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0 flex items-center">
                    <router-link :to="'lecture/livrable/' + $route.params.idTrainingActionStep"> <span
                        class="font-medium text-indigo-600 hover:text-indigo-500 flex items-center">
                        Voir
                      </span></router-link>
                    <router-link :to="'edition/livrable/' + $route.params.idTrainingActionStep"
                      v-if="currentProfile.role?.name === 'Apprenant·e'"> <span
                        class="font-medium text-indigo-600 hover:text-indigo-500 ml-6">
                        Modifier
                      </span></router-link>
                  </div>
                </div>

              </div>
              <!-- notes -->
              <div class="rounded-md border border-gray-200 bg-white mt-5" v-for="note in trainingStep?.notes_by_roles"
                :key="note.id">

                <router-link :to="'lecture/note/' + $route.params.idTrainingActionStep">
                  <div class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7">
                    <div class="flex w-0 flex-1 items-center">
                      <div class="mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                      </div>
                      <div class="ml-4 flex min-w-0 flex-1 gap-2">
                        <span class="truncate font-medium">Note enregistrée par {{ note.role_name }}</span>
                      </div>
                    </div>
                    <div class="ml-4 flex-shrink-0">
                      <button @click.prevent="noteSelected = note; displayNoteRead = true; componentKey++"
                        class="font-medium text-indigo-600 hover:text-indigo-500">Voir</button>
                      <router-link :to="'edition/note/' + $route.params.idTrainingActionStep"
                        v-if="currentProfile.role?.name === note.role_name"> <button type="button"
                          class="font-medium text-indigo-600 hover:text-indigo-500 ml-6">Modifier</button></router-link>
                    </div>
                  </div>
                </router-link>

              </div>

              <!-- documents -->
              <div class="rounded-md border border-gray-200 bg-white mt-5"
                v-for="document in trainingStep?.trainee_documents" :key="document.id">
                <div class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7">
                  <div class="flex w-0 flex-1 items-center">
                    <div class="mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-green-100">
                      <DocumentIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">{{ document?.name }}</span>
                      <span class="flex-shrink-0 text-gray-500">enregistré par l'apprenant•e</span>
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <button @click.prevent="displayDocument = true; documentSelected = document; componentKey++"
                      class="font-medium text-indigo-600 hover:text-indigo-500">Voir</button>
                  </div>
                </div>
              </div>

              <!-- programmation situations -->

              <div v-if="templateStep?.name === 'Rendez-vous 2'">
                <h3 class="text-base font-semibold leading-7  text-indigo-700 mt-10 mb-5">Programmer les 4
                  situations
                </h3>
                <div class="flex">
                  <div class="" v-for="situation in studentSituations" :key="situation.id"> <button type="button"
                      @click.prevent="selectedStep = situation; displayDateEdition = true; componentKey++"
                      class="btn-read flex-col">
                      <div class="text-indigo-600">{{ situation?.name }}</div>
                      <div v-if="situation.start_datetime">{{
                        fetchEventServices.formatDateSimple(new
                          Date(situation.start_datetime)) }}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import { PaperClipIcon, CalendarDaysIcon, CheckIcon, ArrowDownTrayIcon, ArrowUpTrayIcon, PencilSquareIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/vue/20/solid';
import { DocumentIcon } from '@heroicons/vue/24/outline';
import { ChevronLeftIcon } from '@heroicons/vue/20/solid'
import AddDocument from '@/components/files/AddDocument.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';
import TrainingActionStepChangeDate from '@/components/steps/TrainingActionStepChangeDate.vue';
import ModalNoteRead from '@/components/steps/ModalNoteRead.vue';
import ReadDocument from '@/components/files/ReadDocument.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const displayEditFile = ref(false);
let typeSelected = ref(null);
let componentKey = ref(0);
let documentSelected = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const studentSituations = ref([]);
const displayNoteRead = ref(false);
const noteSelected = ref(null);
const displayDocument = ref(false);

const trainingStep = ref([]);
const templateStep = ref([]);
const route = useRoute();
const displayDateEdition = ref(false);
const selectedStep = ref(null);

const templateSteps = ref([
  {
    id: '1',
    step_type: "rendezvous",
    name: "Rendez-vous 1",
    description: "Le but ici est de présenter la FEST à l’encadrement de la structure pour que la FEST puisse se mettre en place dans les bonnes conditions.",
    folder_ids: [],
    support_documents: [{
      id: 23,
      name: "Rendez-vous 1 : Cadrage du projet avec l’encadrement de la structure",
      description: "",
    }, {
      id: 33,
      name: "Fiche référence de l’activité « entrer en relation avec »",
      description: "",
    }],
    reflexive_interview_amont_document_ids: [],
    reflexive_interview_aval_document_ids: [],
    goals: [
      '<p>Le but ici est de présenter la FEST à l’encadrement de la structure (qui peut être, ou non, le formateur métier) pour que la FEST puisse se mettre en place <b class="bold">dans les bonnes conditions</b>. Par ailleurs, il s’agit de faire prendre conscience des « exigences » de la FEST, mais aussi de <b>son intérêt pour l’apprenant, le formateur métier et la structure.</b></p>',
    ],
    expectations: [
      '<p><b>Pour l’encadrement</b>, comprendre ce que la FEST exige et quels intérêt cette modalité peut présenter pour la structure (par exemple pour l’intégration de nouveaux salariés, le questionnement des pratiques, …).<br></p><p><b> Pour le formateur référent</b>, vérifier que la structure donne les moyens de mettre en place la FEST, mais aussi percevoir son positionnement par rapport à la dynamique FEST.',
    ],
    roles: [
      { role_id: 1, role_name: "Encadrement" },
      { role_id: 2, role_name: "Formateur·trice référent·e" }
    ],
    reflexive_interview_amont_text: "",
    reflexive_interview_aval_text: "",
    delivrable: []
  },
  {
    id: '2',
    step_type: "rendezvous",
    name: "Rendez-vous 2",
    description: "Le but ici est de préparer la mise en place des FEST pour l’apprenant : positionnement de l’apprenant par rapport à la fiche référence de l’activité, identification des situations pertinentes, et préparation de ces situations.",
    folder_ids: [],
    support_documents: [{
      id: 22,
      name: "RDV 2 : Construction du parcours FEST avec le formateur métier et l’apprenant",
      description: "",
    }, {
      id: 33,
      name: "Fiche référence de l’activité « entrer en relation avec »",
      description: "",
    }, {
      id: 25,
      name: "Fiche de suivi de positionnement de l’apprenant",
      description: "",
    }],
    reflexive_interview_amont_document_ids: [],
    reflexive_interview_aval_document_ids: [],
    goals: [
      '<p>Le but ici est de préparer la mise en place des FEST pour l’apprenant : positionnement de l’apprenant par rapport à la fiche référence de l’activité, à partir de ça identification des situations qui pourraient être pertinentes à transformer en FEST, mais aussi comment aménager ces situations : permettre le droit à l’essai et à l’erreur, permettre au formateur métier et référent d’observer l’apprenant sans intervenir, prévenir les collègues, voir s’il faut prévenir le public, … Et toutes les autres questions à se poser./p>',
    ],
    expectations: [
      '<p><b>Pour le formateur métier</b>, comprendre ce qui est attendu de lui (la FEST n’est pas une évaluation de l’apprenant) et comment jouer ce rôle (via les entretiens réflexifs). Mais aussi planifier les mises en situation de FEST pour pouvoir organiser l’activité de la structure en fonction.</p><p><b>Pour le formateur référent</b>, permettre à l’apprenant de se positionner, définir les situations de travail qui seront aménagées en mises en situation FEST, planifier les 4 mises en situation, encourager l’apprenant à être dans une posture réflexive (ce n’est pas une évaluation).</p><p><b>Pour l’apprenant</b>, voir l’intérêt de la FEST pour son parcours de formation, se situer par rapport à la fiche référence de l’activité, identifier des situations de travail qui vont lui permettre de « tester des choses ».</p>',
    ],
    roles: [
      { role_id: 3, role_name: "Formateur·trice référent·e" },
      { role_id: 4, role_name: "Formateur·trice métier" }
    ],
    reflexive_interview_amont_text: "",
    reflexive_interview_aval_text: ""
  },
  {
    id: '3',
    delivrable_template: [1],
    step_type: "situation",
    name: "Situation de travail 1",
    description: "L’apprenant est mis en situation pour mettre en pratique la réflexivité avec le soutien du formateur métier et référent.",
    folder_ids: [],
    support_document_ids: [],
    reflexive_interview_amont_documents: [{
      id: 33,
      name: "Fiche référence de l’activité « entrer en relation avec »",
      description: "Description for document 3 in folder Matériaux de Cours",
    }],
    reflexive_interview_aval_documents: [{
      id: 25,
      name: "Fiche de suivi de positionnement de l’apprenant",
      description: "Description for document 3 in folder Matériaux de Cours",
    },
    {
      id: 36,
      name: "Les questions types pour recueillir la description de l'activité reelle",
      description: "",
    }
    ],
    goals: [
      '<p>Le but est de permettre à l’apprenant de faire et de réfléchir à comment il s’y est pris (la fameuse réflexivité). Pour ça, on commence par un entretien en amont, ensuite il est mis en situation, puis on réalise l’entretien en aval.</p>'],
    expectations: ['<p><b>Pour le formateur référent</b>, aider l’apprenant à mettre en mot sa pratique, à lui faire percevoir ce qui est solide ou plus fragile dans ce qu’il fait.</p><p><b>Pour le formateur métier</b>, voir comment l’apprenant « s’y prend », voir le sens qu’il met derrière ses actes. Mais aussi identifier d’autres manières de faire possibles, voire des choses à modifier dans l’organisation pour améliorer ce qui peut être fait, … </p><p><b>Pour l’apprenant</b>, agir et mettre en mots ses actes, ses intentions et ses réflexions, via la réflexivité. Mais aussi échanger avec le formateur métier sur les différentes façons de faire, sur l’organisation de la structure qui permet ou qui contraint ces façons de faire, …</p>',
    ],
    roles: [
      { role_id: 4, role_name: "Formateur·trice métier" },
      { role_id: 2, role_name: "Apprenant·e" },
      { role_id: 3, role_name: "Formateur·trice référent·e" }
    ],
    reflexive_interview_amont_text: "L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …",
    reflexive_interview_aval_text: "L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.",
    delivrable: [{
      name: "Fiche de suivi de positionnement Formation FEST",
      sections: [],
    }],
  },
  {
    id: '4',
    step_type: "situation",
    name: "Situation de travail 2",
    delivrable_template: [1],
    description: "L’apprenant est mis en situation pour mettre en pratique la réflexivité avec le soutien du formateur métier et référent.",
    folder_ids: [],
    support_document_ids: [],
    reflexive_interview_amont_documents: [{
      id: 33,
      name: "Fiche référence de l’activité « entrer en relation avec »",
      description: "Description for document 3 in folder Matériaux de Cours",
    }],
    reflexive_interview_aval_documents: [{
      id: 25,
      name: "Fiche de suivi de positionnement de l’apprenant",
      description: "Description for document 3 in folder Matériaux de Cours",
    },
    {
      id: 36,
      name: "Les questions types pour recueillir la description de l'activité reelle",
      description: "",
    }],
    goals: [
      '<p>Le but est de permettre à l’apprenant de faire et de réfléchir à comment il s’y est pris (la fameuse réflexivité). Pour ça, on commence par un entretien en amont, ensuite il est mis en situation, puis on réalise l’entretien en aval.</p>'],
    expectations: ['<p><b>Pour le formateur métier</b>, voir comment l’apprenant « s’y prend », voir le sens qu’il met derrière ses actes. Mais aussi identifier d’autres manières de faire possibles, voire des choses à modifier dans l’organisation pour améliorer ce qui peut être fait, … </p><p><b>Pour l’apprenant</b>, agir et mettre en mots ses actes, ses intentions et ses réflexions, via la réflexivité. Mais aussi échanger avec le formateur métier sur les différentes façons de faire, sur l’organisation de la structure qui permet ou qui contraint ces façons de faire, …</p>',
    ],
    roles: [
      { role_id: 4, role_name: "Formateur·trice métier" },
      { role_id: 2, role_name: "Apprenant·e" },
    ],
    reflexive_interview_amont_text: "L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …",
    reflexive_interview_aval_text: "L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.",
    delivrable: [{
      name: "Fiche de suivi de positionnement Formation FEST",
      id: 293,
    }],
  },
  {
    id: '5',
    step_type: "situation",
    name: "Situation de travail 3",
    delivrable_template: [1],
    description: "L’apprenant est mis en situation pour mettre en pratique la réflexivité avec le soutien du formateur métier et référent.",
    folder_ids: [],
    support_document_ids: [],
    reflexive_interview_amont_documents: [{
      id: 33,
      name: "Fiche référence de l’activité « entrer en relation avec »",
      description: "Description for document 3 in folder Matériaux de Cours",
    }],
    reflexive_interview_aval_documents: [{
      id: 25,
      name: "Fiche de suivi de positionnement de l’apprenant",
      description: "Description for document 3 in folder Matériaux de Cours",
    },
    {
      id: 36,
      name: "Les questions types pour recueillir la description de l'activité reelle",
      description: "",
    }],
    goals: [
      '<p>Le but est de permettre à l’apprenant de faire et de réfléchir à comment il s’y est pris (la fameuse réflexivité). Pour ça, on commence par un entretien en amont, ensuite il est mis en situation, puis on réalise l’entretien en aval.</p>'],
    expectations: ['<p><b>Pour le formateur métier</b>, voir comment l’apprenant « s’y prend », voir le sens qu’il met derrière ses actes. Mais aussi identifier d’autres manières de faire possibles, voire des choses à modifier dans l’organisation pour améliorer ce qui peut être fait, … </p><p><b>Pour l’apprenant</b>, agir et mettre en mots ses actes, ses intentions et ses réflexions, via la réflexivité. Mais aussi échanger avec le formateur métier sur les différentes façons de faire, sur l’organisation de la structure qui permet ou qui contraint ces façons de faire, …</p>',
    ],
    roles: [
      { role_id: 4, role_name: "Formateur·trice métier" },
      { role_id: 2, role_name: "Apprenant·e" },
    ],
    reflexive_interview_amont_text: "L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …",
    reflexive_interview_aval_text: "L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.",
    delivrable: [{
      name: "Fiche de suivi de positionnement Formation FEST",
      id: 293,
    }],
  },
  {
    id: '6',
    delivrable_template: [1],
    step_type: "situation",
    name: "Situation de travail 4",
    description: "L’apprenant est mis en situation pour mettre en pratique la réflexivité avec le soutien du formateur métier et référent.",
    folder_ids: [],
    support_document_ids: [],
    reflexive_interview_amont_documents: [{
      id: 33,
      name: "Fiche référence de l’activité « entrer en relation avec »",
      description: "Description for document 3 in folder Matériaux de Cours",
    }],
    reflexive_interview_aval_documents: [{
      id: 25,
      name: "Fiche de suivi de positionnement de l’apprenant",
      description: "Description for document 3 in folder Matériaux de Cours",
    },
    {
      id: 36,
      name: "Les questions types pour recueillir la description de l'activité reelle",
      description: "",
    }],
    goals: [
      '<p>Le but est de permettre à l’apprenant de faire et de réfléchir à comment il s’y est pris (la fameuse réflexivité). Pour ça, on commence par un entretien en amont, ensuite il est mis en situation, puis on réalise l’entretien en aval.</p>'],
    expectations: ['<p><b>Pour le formateur référent</b>, aider l’apprenant à mettre en mot sa pratique, à lui faire percevoir ce qui est solide ou plus fragile dans ce qu’il fait.</p><p><b>Pour le formateur métier</b>, voir comment l’apprenant « s’y prend », voir le sens qu’il met derrière ses actes. Mais aussi identifier d’autres manières de faire possibles, voire des choses à modifier dans l’organisation pour améliorer ce qui peut être fait, … </p><p><b>Pour l’apprenant</b>, agir et mettre en mots ses actes, ses intentions et ses réflexions, via la réflexivité. Mais aussi échanger avec le formateur métier sur les différentes façons de faire, sur l’organisation de la structure qui permet ou qui contraint ces façons de faire, …</p>',
    ],
    roles: [
      { role_id: 4, role_name: "Formateur·trice métier" },
      { role_id: 2, role_name: "Apprenant·e" },
      { role_id: 3, role_name: "Formateur·trice référent·e" }
    ],
    reflexive_interview_amont_text: "L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …",
    reflexive_interview_aval_text: "L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.",
    delivrable: [{
      name: "Fiche de suivi de positionnement Formation FEST",
      sections: [],
    }],
  },
  {
    id: '7',
    delivrable_template: null,
    step_type: "bilan",
    name: "Bilan",
    description: "L’apprenant est mis en situation pour mettre en pratique la réflexivité avec le soutien du formateur métier et référent.",
    folder_ids: [],
    support_document_ids: [],
    reflexive_interview_amont_documents: [{
      id: 33,
      name: "Fiche référence de l’activité « entrer en relation avec »",
      description: "Description for document 3 in folder Matériaux de Cours",
    }],
    reflexive_interview_aval_documents: [{
      id: 25,
      name: "Fiche de suivi de positionnement de l’apprenant",
      description: "Description for document 3 in folder Matériaux de Cours",
    }],
    goals: [
      'L\'enjeu du bilan est de permettre à l’apprenant et au formateur métier de mettre en mots ce qu’ils ont appris, compris, et modifié dans leurs pratiques, ce qu’ils aimeraient dire aux futurs personnes qui vont faire des mises en situation…',
    ],
    expectations: null,
    roles: [
      { role_id: 4, role_name: "Formateur·trice métier" },
      { role_id: 2, role_name: "Apprenant·e" },
      { role_id: 3, role_name: "Formateur·trice référent·e" }
    ],
    reflexive_interview_amont_text: "L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …",
    reflexive_interview_aval_text: "L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.",
    delivrable: [],
  },
]);

const editFile = (type) => {
  typeSelected.value = type;
  displayEditFile.value = true;
  componentKey.value += 1;
}

const attachFile = async (file) => {
  let traineeDocumentIds = [];
  let arrayToPost = {};
  arrayToPost.folder_ids = trainingStep.value.folders.map(folder => folder.id);
  if (trainingStep.value.trainee_documents.length > 0) {
    traineeDocumentIds = trainingStep.value.trainee_documents.map(doc => doc.id);
  }
  trainingStep.value.user_document_ids = traineeDocumentIds;
  trainingStep.value.user_document_ids.push(file);
  arrayToPost.user_document_ids = trainingStep.value.user_document_ids;
  try {
    const response = await client.put(`/api/training-action-step/${route.params.idTrainingActionStep}`, arrayToPost);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = response.data.message;
    notificationMessage.value = '';
    getTrainigStep();
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans l\'association du fichier à l\'étape';
    notificationMessage.value = '';
    if (error.response && error.response.data) {
      notificationMessage.value += error.response.data.message;
    }
  }
}

const getTrainigStep = async () => {
  try {
    const response = await client.get(`/api/training-action-step/${route.params.idTrainingActionStep}`);
    templateStep.value = templateSteps.value.find(step => step.name === response.data.name);
    trainingStep.value = response.data;
    if (response.data.name === 'Rendez-vous 2') {
      getSituations(response.data.training_action_id, response.data.profile_id);
    }
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans la récupération de l\'étape';
  }
}

const downloadFile = async (fileId) => {
  try {
    // Make an HTTP GET request to the backend to download the file
    const response = await client.get(`api/document-files/${fileId}/download`, {
      responseType: 'blob', // Expect a binary file (blob) in the response
    });

    if (response.status === 200) {
      // Try to access the Content-Disposition header from the response
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file'; // Default file name in case extraction fails

      // Extract the filename from the Content-Disposition header using a regex
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)|filename="?([^"]+)"?/);

        if (fileNameMatch) {
          if (fileNameMatch[1]) {
            // Handle the case where the filename is UTF-8 encoded
            fileName = decodeURIComponent(fileNameMatch[1]);
          } else if (fileNameMatch[2]) {
            // Handle the case where the filename is straightforward
            fileName = fileNameMatch[2];
          }
        }
      }

      // Create a Blob URL for the file content and initiate the download
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName); // Set the correct filename for the download
      document.body.appendChild(fileLink);
      fileLink.click(); // Simulate a click on the anchor to trigger the download

      // Clean up after the download
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL); // Release the blob URL
    }
  } catch (error) {
    // Handle any errors that occur during the request or download
    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        notificationMessage.value = errorData.error || "Une erreur est survenue lors du téléchargement du document.";
      };
      reader.readAsText(error.response.data);
    } else {
      notificationMessage.value = error.message || "Une erreur est survenue lors du téléchargement du document.";
    }

    // Show an error notification to the user
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans la récupération du fichier';
  }
};

const getSituations = async (idTrainingAction, idProfile) => {
  try {
    const response = await client.get(`/api/training-action/${idTrainingAction}/steps/${idProfile}`);
    studentSituations.value = response.data.filter(step => step.step_type === 'situation');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans la récupération des prochaines situations de travail';
  }
}

onMounted(async () => {
  getTrainigStep();
});

</script>
<style scoped>
b {
  @apply font-medium text-black;
  font-weight: 600;
  color: #000;
}

.bold {
  font-weight: 600;
  color: #000;
}

p {
  @apply mb-5;
}
</style>
