<template>
  <AppLayout>
    <div class="bg-white rounded-3xl pt-5 max-w-[1400px] mx-auto  px-4 sm:px-6 lg:px-10 pb-36"
      style="min-height: calc( 100vh - 400px );">
      <div class="mx-auto">
        <ProfileHeader />
      </div>
      <div>
        <div class="" v-if="currentProfile.role.name === 'Administrateur·trice'">
          <div class="">
            <NavBarAdmin v-model="componentToDisplay" :currentProfile="currentProfile.role.name" />
          </div>
          <div class=" flex-1 pt-10">
            <AdminDashboardAdministrator v-model="componentToDisplay" v-if="componentToDisplay === 'home'"
              :currentProfile="currentProfile.role.name" />
            <AdminStructuresList v-if="componentToDisplay === 'structures'" />
            <AdminOrganizationsList v-if="componentToDisplay === 'organizations'" />
            <AdminUsersList v-if="componentToDisplay === 'users'" />
            <AdminLegalFormList v-if="componentToDisplay === 'legalForms'" />
            <AdminTypesList v-if="componentToDisplay === 'types'" />
            <AdminPublicsList v-if="componentToDisplay === 'publics'" />
            <AdminWishlistList v-if="componentToDisplay === 'wishlists'" />
            <AdminDegreesList v-if="componentToDisplay === 'degrees'" />
            <AdminTrainingActions v-if="componentToDisplay === 'training-actions'" />
            <AdminEvents v-if="componentToDisplay === 'events'" />
            <AdminSteps v-if="componentToDisplay === 'steps'" />
          </div>
        </div>
        <div
          v-if="currentProfile.role.name === ('Coordinateur·trice') || currentProfile.role.name === 'Assistante de formation'">
          <HomeCoordinator />
          <div class="">
            <NavBarAdmin v-model="componentToDisplay" :currentProfile="currentProfile.role.name" />
          </div>
          <div class=" flex-1 pt-10">
            <AdminDashboardAdministrator v-model="componentToDisplay" v-if="componentToDisplay === 'home'"
              :currentProfile="currentProfile.role.name" />
            <AdminStructuresList v-if="componentToDisplay === 'structures'" />
            <AdminOrganizationsList v-if="componentToDisplay === 'organizations'" />
            <AdminUsersList v-if="componentToDisplay === 'users'" />
            <AdminWishlistList v-if="componentToDisplay === 'wishlists'" />
            <AdminTrainingActions v-if="componentToDisplay === 'training-actions'" />
            <AdminSteps v-if="componentToDisplay === 'steps'" />
          </div>
        </div>
        <div v-if="currentProfile.role.name === 'Formateur·trice référent·e'">
          <HomeReferent />
        </div>
        <div v-if="currentProfile.role.name === 'Apprenant·e'" class="bg-white py-5">
          <HomeStudent />
          <div class="mt-10">
          </div>
        </div>

        <div v-if="currentProfile.role.name === 'Formateur·trice métier'" class="bg-white py-10">
          <HomeTutor />
          <FilesManager v-if="componentToDisplay === 'documents'" />
        </div>
      </div>
    </div>

  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import {
  inject, onMounted, watch, ref,
} from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ProfileHeader from '@/components/profiles/ProfileHeader.vue';
import { useRouter, useRoute } from 'vue-router';
import NavBarAdmin from '@/components/app/NavBarAdmin.vue';
import AdminStructuresList from '@/components/admin/AdminStructuresList.vue';
import AdminOrganizationsList from '@/components/admin/AdminOrganizationsList.vue';
import AdminUsersList from '@/components/admin/AdminUsersList.vue';
import AdminLegalFormList from '@/components/admin/AdminLegalFormList.vue';
import AdminTypesList from '@/components/admin/AdminTypesList.vue';
import AdminPublicsList from '@/components/admin/AdminPublicsList.vue';
import AdminWishlistList from '@/components/admin/AdminWishlistList.vue';
import AdminDegreesList from '@/components/admin/AdminDegreesList.vue';
import AdminDashboardAdministrator from '@/components/dashboard/AdminDashboardAdministrator.vue';
import AdminDashboardCoordinator from '@/components/dashboard/AdminDashboardCoordinator.vue';
import AdminTrainingActions from '@/components/admin/AdminTrainingActions.vue';
import HomeTutor from '@/components/dashboard/HomeTutor.vue';
import AdminEvents from '@/components/admin/AdminEvents.vue';
import HomeStudent from '@/components/dashboard/HomeStudent.vue';
import FilesManager from '@/components/files/FilesManager.vue';
import AdminSteps from '@/components/admin/AdminSteps.vue';
import HomeReferent from '@/components/dashboard/HomeReferent.vue';
import HomeCoordinator from '@/components/dashboard/HomeCoordinator.vue';

const store = inject('store');
const router = useRouter();
const route = useRoute();
const currentProfile = ref(store.chosenProfile);
const componentToDisplay = ref('account');

watch(() => store.chosenProfile, (newProfile) => {
  currentProfile.value = newProfile;
});

watch(() => componentToDisplay, (newComponent) => {
  console.log('newComponent', newComponent);
  router.push({ query: { tab: newComponent } });
});

onMounted(() => {
  store.refreshUserProfiles();
  if (store.userProfiles.length === 0) {
    router.push({ name: 'Créer un profil' });
  }
  if (route.query.tab) {
    componentToDisplay.value = route.query.tab;
  } else {
    componentToDisplay.value = 'home';
  }
});
</script>
