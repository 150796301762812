<template>

  <Listbox as="div" v-model="selected" class="pt-10 mt-10" style="z-index:100; position:relative">
    <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Choisissez l'action de formation
    </ListboxLabel>
    <div class="relative mt-2">
      <ListboxButton
        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <span class="flex items-center" v-if="selected">
          <span class="block truncate font-semibold"><span
              :class="[new Date(selected.end_date) > new Date() ? 'bg-green-400' : 'bg-gray-200', 'inline-block h-2 w-2 flex-shrink-0 rounded-full mr-1']"
              aria-hidden="true" />{{ selected?.degree?.name }} - {{ selected?.year }}</span>
        </span><span v-else>Aucune action sélectionnée</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="trainingAction in relevantTrainingActions" :key="trainingAction.id"
            :value="trainingAction" v-slot="{ active, selected }" @click="$emit('confirm', selected)">
            <li
              :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
              <div class="flex items-center">
                <span
                  :class="[new Date(trainingAction.end_date) > new Date() ? 'bg-green-400' : 'bg-gray-200', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']"
                  aria-hidden="true" />
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                  {{ trainingAction.degree.name }} - {{ trainingAction.year }}
                  <span class="sr-only"> is {{ new Date(trainingAction.end_date) > new Date() ? 'online' : 'offline'
                    }}</span>
                </span>
              </div>

              <span v-if="selected"
                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import client from '@/api/client';

const relevantTrainingActions = ref([]);

const selected = ref();
const emit = defineEmits(['confirm', 'onlyOneTrainingAction']);
const props = defineProps({
  action: [String, Number],
});

onMounted(async () => {
  try {
    const response = await client.get(`/api/dashboard/training-actions-folders`);
    relevantTrainingActions.value = response.data.training_actions;
    if (props.action) {
      selected.value = relevantTrainingActions.value.find(action => Number(action.id) === Number(props.action));
    }
    if (relevantTrainingActions.value.length === 1) {
      emit('onlyOneTrainingAction', response.data.training_actions[0]);
      selected.value = response.data.training_actions[0];
    }
  } catch (error) {
  }
});
</script>