<template>
    <div class="relative border-b border-gray-200 pb-5 sm:pb-0">

        <div class="mt-4">
            <div class="sm:hidden">
                <label for="current-tab" class="sr-only">Sélectionner un onglet</label>
                <select id="current-tab" name="current-tab" v-model="model"
                    class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                    <option v-for="tab in navigationRole" :key="tab.name" :selected="tab.href === model"
                        :value="tab.href">
                        {{ tab.name }}
                    </option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="-mb-px flex space-x-8">
                    <button v-for="tab in navigationRole" :key="tab.name" class="flex items-center"
                        @click="$emit('update:modelValue', tab.href); gotoTab(tab.href)"
                        :class="[tab.href === model ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium']"
                        :aria-current="tab.href === model ? 'page' : undefined">
                        <component :is="tab.icon"
                            :class="[tab.href === model ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                            aria-hidden="true" />
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
    ListBulletIcon,
    HomeIcon,
    BuildingOffice2Icon,
    UsersIcon,
    UserIcon,
    TagIcon,
    BuildingLibraryIcon,
    AcademicCapIcon,
    PresentationChartLineIcon,
    CalendarIcon, FlagIcon,
    HomeModernIcon,
} from '@heroicons/vue/24/outline'

const model = defineModel();
const props = defineProps({
    currentProfile: [String],
});
const router = useRouter();
const navigation = [
    { name: 'Mon compte', href: 'home', icon: HomeIcon, count: '5', current: false, allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice'] },
    { name: 'Événements', href: 'events', icon: CalendarIcon, current: false, allowedRoles: ['Administrateur·trice'] },
    { name: 'Actions de formation', href: 'training-actions', icon: PresentationChartLineIcon, current: false, allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice'] },
    { name: 'Structures', href: 'structures', icon: BuildingOffice2Icon, current: false, allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice'] },
    { name: 'Utilisateurs', href: 'users', icon: UsersIcon, count: '12', current: true, allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice'] },
    { name: 'Organisations', href: 'organizations', icon: BuildingLibraryIcon, count: '20+', current: false, allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice'] },
    { name: 'Types de structure', href: 'types', icon: HomeModernIcon, current: false, allowedRoles: ['Administrateur·trice'] },
    { name: 'Publics', href: 'publics', icon: UserIcon, current: false, allowedRoles: ['Administrateur·trice'] },
    { name: 'Statuts', href: 'legalForms', icon: TagIcon, current: false, allowedRoles: ['Administrateur·trice'] },
    { name: 'Diplômes', href: 'degrees', icon: AcademicCapIcon, current: false, allowedRoles: ['Administrateur·trice'] },
    { name: 'Whishlists', href: 'wishlists', icon: ListBulletIcon, current: false, allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice'] },
    { name: 'Étapes Fest', href: 'steps', icon: FlagIcon, current: false, allowedRoles: ['Coordinateur·trice', 'Assistant·e de formation', 'Administrateur·trice'] },
]
const navigationRole = ref();
const emit = defineEmits(['update:modelValue']);

const gotoTab = (tabName) => {
    router.push({ query: { tab: tabName } });
}

onMounted(() => {
    navigationRole.value = navigation.filter(tab => tab.allowedRoles.includes(props.currentProfile));
}); 
</script>
