<template>
  <div>
    <div v-for="learner in studentSteps" :key="learner.learner.id" class="mb-10">
      <p class="text-lg font-medium">{{ learner.learner.name }}</p>
      <div v-for="action in learner.training_actions" :key="action.start_date">
        <div class="text-indigo-700 font-medium">{{ action.degree_name }} - <span class="text-sm text-gray-500">{{
          fetchEventServices.formatDateSimple(new
            Date(action.start_date)) }} / {{ fetchEventServices.formatDateSimple(new Date(action.end_date)) }}</span>
        </div>
        <TimelineStepsByStudent :steps="action.steps" :key="componentKey" v-if="action.steps.length > 0" />
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, inject, ref } from 'vue';
import TimelineStepsByStudent from '@/components/steps/TimelineStepsByStudent.vue';
import fetchEventServices from '@/services/EventServices.js';
import client from '@/api/client';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const studentSteps = ref([]);
const componentKey = ref(0);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');

const getStudentSteps = async () => {
  console.log('getStudentSteps');
  try {
    const response = await client.get(`/api/tutor/${currentProfile.value.id}/learners-with-steps`);
    console.log(response.data);
    studentSteps.value = response.data;
    componentKey.value += 1;
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans le chargement des étapes';
  }
};

onMounted(() => {
  getStudentSteps();
});
</script>
