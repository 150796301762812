<template>
    <div class="mx-auto px-2 sm:px-4 lg:px-28 xl:px-32">
        <h2 class="mb-10 mt-10 text-3xl">Mes apprenants</h2>
        <TutorSteps rights="'read'" :profileId="currentProfile.id" />

        <h2 class="mb-10 mt-10  text-3xl">Mes documents</h2>
        <div class="mt-10 mb-20">
            <NewDocuments />
        </div>

        <h2 class="mb-5 mt-10  text-3xl">Mes structures</h2>
        <div v-if="currentProfile.structures.length">
            <div class="overflow-hidden bg-white">
                <div v-for="attachedStructure in currentProfile.structures" :key="attachedStructure.id" class="">
                    <StructureCard :displayedStructure="attachedStructure" :typeCard="'admin'"
                        @displayStructure="displayStructure(attachedStructure)" />
                </div>
            </div>
        </div>

        <router-link to="/structures/creer-ma-structure"><button type="button"
                class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <BuildingOffice2Icon class="w-12 h-12 stroke-1 stroke-gray-300 mx-auto mb-5 mx-auto mb-5" />
                <span class="mt-2 block text-md font-medium text-gray-900">Créer une nouvelle structure</span>
            </button></router-link>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, inject, watch } from 'vue';
import { BuildingOffice2Icon } from '@heroicons/vue/24/outline';
import StructureCard from '@/components/structures/StructureCard.vue';
import NewDocuments from '@/components/files/NewDocuments.vue';
import TutorSteps from '@/components/steps/TutorSteps.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);

watch(() => store.chosenProfile, (newProfile) => {
    currentProfile.value = newProfile;
});
</script>

<style></style>
